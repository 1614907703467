.topnav {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-icon {
  width: 50px;
  justify-content: center;
  align-items: center;
  color: black;
}

.active-nav-icon {
  color: black !important;
}

.app-content {
  max-width: 600px;
  /* margin-right: auto; */
  padding: 30px;
}